import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'


export default function LogoPopover() {
  return (

    <Popover className="relative">
      <Popover.Button aria-label="What does Big Pi mean?" className="focus:outline-none inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">

        <div className="cursor-help" href="#">
          <svg className="h-[75px] sm:h-[100px]" viewBox="0 0 469 181" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_566_359)">
              <path d="M219.968 118V112.234H224.308V80.3661H219.968V74.6001H240.242C248.674 74.6001 253.51 79.1881 253.51 85.4501C253.51 89.6661 250.72 92.9521 247.186 93.4481V94.6881C250.038 95.3701 254.874 98.7801 254.874 105.538C254.874 112.978 249.17 118 241.172 118H219.968ZM230.632 112.172H240.924C245.14 112.172 248.302 109.444 248.302 105.29C248.302 101.012 245.14 98.3461 240.924 98.3461H230.632V112.172ZM230.632 92.8901H240.366C244.396 92.8901 246.938 90.1621 246.938 86.6901C246.938 82.9701 244.21 80.4281 240.366 80.4281H230.632V92.8901Z" fill="white"/>
              <path d="M260.998 118V86.1321H267.198V118H260.998ZM260.936 80.5521V73.2361H267.198V80.5521H260.936Z" fill="white"/>
              <path d="M287.55 131.826C278.746 131.826 271.864 128.85 271.864 122.216C271.864 118.744 274.53 115.644 278.808 115.644H279.676V114.404C277.134 113.846 275.584 111.986 275.584 109.568C275.584 107.336 276.948 105.786 278.25 105.042C276.018 103.182 274.654 100.33 274.654 96.8581C274.654 89.8521 279.986 85.3261 287.364 85.3261C288.852 85.3261 290.34 85.5741 291.766 86.0701L303.732 85.5121V90.8441H295.176V92.0841C298.028 92.7661 299.826 95.3081 299.826 98.2221C299.826 104.608 293.998 108.142 287.364 108.142C285.876 108.142 284.326 107.956 282.962 107.46C282.342 107.708 281.598 108.452 281.598 109.506C281.598 111.118 283.086 111.8 284.698 111.8H293.874C300.198 111.8 304.228 115.458 304.228 121.038C304.228 129.594 295.052 131.826 287.55 131.826ZM278.126 121.286C278.126 125.316 282.59 126.432 287.55 126.432C293.254 126.432 297.966 125.13 297.966 121.224C297.966 118.868 296.168 117.132 292.696 117.132H283.086C280.11 117.132 278.126 118.868 278.126 121.286ZM280.73 96.8581C280.73 100.764 283.334 103.43 287.364 103.43C291.456 103.43 294.06 100.764 294.06 96.8581C294.06 93.2621 291.456 90.4721 287.364 90.4721C283.334 90.4721 280.73 93.2621 280.73 96.8581Z" fill="white"/>
              <path d="M325.542 118V74.6001H341.786C349.908 74.6001 355.736 80.1181 355.736 87.9921C355.736 95.8041 349.784 101.694 341.786 101.694H331.928V118H325.542ZM331.928 95.8041H341.29C345.94 95.8041 349.226 92.5181 349.226 87.9921C349.226 83.5901 345.94 80.4281 341.29 80.4281H331.928V95.8041Z" fill="white"/>
              <path d="M360.663 118V86.1321H366.863V118H360.663ZM360.601 80.5521V73.2361H366.863V80.5521H360.601Z" fill="white"/>
              <path d="M231.81 180L217.302 137.406V136.6H223.998L236.46 174.544H237.762L250.348 136.6H257.044V137.406L242.598 180H231.81Z" fill="white"/>
              <path d="M272.766 180.806C263.218 180.806 256.708 173.8 256.708 163.88C256.708 153.898 263.714 147.326 272.642 147.326C282.314 147.326 288.018 154.704 287.708 163.136L287.584 166.112H262.846C263.59 171.754 267.062 175.35 272.766 175.35C276.672 175.35 279.834 173.552 281.694 170.638L286.282 173.614C283.74 177.892 279.028 180.806 272.766 180.806ZM262.97 160.904H281.694C281.57 156.316 277.85 152.596 272.642 152.596C267.31 152.596 263.962 156.006 262.97 160.904Z" fill="white"/>
              <path d="M293.186 180V148.132H299.386V153.836H300.626C301.618 150.426 304.842 147.326 309.74 147.326C317.118 147.326 321.024 152.844 321.024 158.92V180H314.824V160.718C314.824 156.316 311.538 153.216 307.384 153.216C302.61 153.216 299.386 156.688 299.386 161.4V180H293.186Z" fill="white"/>
              <path d="M339.741 180C334.905 180 331.061 177.396 331.061 171.382V153.402H325.481V148.132H331.185V140.94H337.261V148.132H346.747V153.402H337.261V171.072C337.261 173.552 338.625 174.482 340.547 174.482H346.313V180H339.741Z" fill="white"/>
              <path d="M363.126 180.806C355.81 180.806 351.904 175.35 351.904 169.212V148.132H358.104V167.414C358.104 172.188 361.39 174.916 365.544 174.916C370.318 174.916 373.542 171.444 373.542 166.794V148.132H379.742V180H373.542V174.296H372.302C371.062 178.202 368.024 180.806 363.126 180.806Z" fill="white"/>
              <path d="M387.067 180V148.132H393.205V154.89H394.507C395.437 151.79 398.413 147.574 404.861 147.574V154.208H402.753C396.305 154.208 393.267 158.796 393.267 163.942V180H387.067Z" fill="white"/>
              <path d="M422.958 180.806C413.41 180.806 406.9 173.8 406.9 163.88C406.9 153.898 413.906 147.326 422.834 147.326C432.506 147.326 438.21 154.704 437.9 163.136L437.776 166.112H413.038C413.782 171.754 417.254 175.35 422.958 175.35C426.864 175.35 430.026 173.552 431.886 170.638L436.474 173.614C433.932 177.892 429.22 180.806 422.958 180.806ZM413.162 160.904H431.886C431.762 156.316 428.042 152.596 422.834 152.596C417.502 152.596 414.154 156.006 413.162 160.904Z" fill="white"/>
              <path d="M455.034 180.806C447.656 180.806 442.882 176.9 441.518 171.134L447.16 169.646C448.028 173.552 451.314 175.412 455.22 175.412C459.932 175.412 462.35 173.49 462.35 171.01C462.35 168.902 460.924 167.6 457.824 166.918L452.306 165.74C447.718 164.748 443.502 162.392 443.502 156.626C443.502 151.48 448.09 147.326 455.654 147.326C462.164 147.326 467.062 150.86 468.302 156.502L462.784 157.99C462.226 154.766 459.436 152.596 455.654 152.596C451.748 152.596 449.454 154.394 449.454 156.626C449.454 158.734 451.004 159.912 453.98 160.532L459.374 161.71C464.83 162.888 468.364 165.74 468.364 171.01C468.364 176.9 463.156 180.806 455.034 180.806Z" fill="white"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M60.5 0.5H179.5V179.5H110.5V69.5H69.5V179.5H0.5V60.5H20.5V40.5H40.5V20.5H60.5V0.5ZM60.5 29.5H49.5V40.5H60.5V29.5ZM69.5 40.5V29.5H150.5V170.5H139.5V40.5H69.5ZM60.5 49.5H49.5V60.5H60.5V49.5ZM69.5 60.5V49.5H130.5V170.5H119.5V60.5H69.5ZM60.5 69.5H49.5V170.5H60.5V69.5ZM40.5 170.5V69.5H29.5V170.5H40.5ZM20.5 170.5V69.5H9.5V170.5H20.5ZM29.5 60.5H40.5V49.5H29.5V60.5ZM159.5 170.5V20.5H69.5V9.5H170.5V170.5H159.5Z" fill="#3ADDF2"/>
            </g>
          </svg>
        </div>


      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-10 flex w-screen max-w-max -translate-y-12">
          <div className="w-screen max-w-xs sm:max-w-sm flex-auto bg-white p-2 leading-6 shadow-xl ring-1 ring-bigpi-primary-500/5">
              <div key="" className="relative p-4">
                <h1 className="font-semibold text-bigpi-primary-700 text-lg mb-2">
                  What does Big Pi mean?
                </h1>
                <div className="flex gap-4">
                  <p className="mt-1 text-bigpi-primary-500 leading-snug text-sm"><img className="hidden sm:block float-right ml-6 mb-4 w-[110px]" src="/assets/bigpinotation.svg"/> The Greek capital letter pi (Π) is the product operator in mathematics. It represents the product, or multiplication, of a series of factors. This aligns with our idea that success comes from the combination of different elements that enhance each other.</p>
                </div>
              </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>


    );
};












